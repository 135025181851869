import { Injectable } from '@angular/core';
import { SharedRoute } from '../models/routing.interfaces';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    quickEntry: string;
    route: SharedRoute = { routeName: '', routeText: '' } as SharedRoute;
    altRoute: SharedRoute = { routeName: '', routeText: '' } as SharedRoute;
    constructor(
    ) {}


    setQuickEntryRoute(patientId: string, clientId: string) {
        if (patientId != null) {
            this.quickEntry = `quick-entry/client/${clientId}/patient/${patientId}`;
        } else if (clientId != null) {
            this.quickEntry = `quick-entry/client/${clientId}`;
        } else {
            this.quickEntry = `quick-entry`;
        }
    }


    setRoute(routeName: string, routeText: string) {
        this.route = { routeName, routeText } as SharedRoute;
    }

    setAltRoute(routeName: string, routeText: string) {
        this.altRoute = { routeName, routeText } as SharedRoute;
    }

    clear() {
        this.route.routeName = '';
        this.route.routeText = '';
        this.altRoute.routeName = '';
        this.altRoute.routeText = '';
        this.quickEntry = '';
    }

}
