import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { PatientService } from 'src/app/patient-management/services/patient.service';

@Component({
  selector: 'app-multiselect-recent-medications',
  templateUrl: './multiselect-recent-medications.component.html',
  styleUrls: ['./multiselect-recent-medications.component.scss']
})
export class MultiselectRecentMedicationsComponent implements OnInit, OnDestroy {

  recentMedicationsList: string[];
  selectedRecentMedications: string[];
  formSearch: FormControl = new FormControl('');

  @Input() recentMedicationControl: FormControl;
  @Input() patient: Patient;

  subscription: Subscription = new Subscription();


  constructor(
    public patientService: PatientService,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.subscription.add(this.recentMedicationControl.valueChanges.subscribe(this.updateSelectedRecentMedications.bind(this)));
    this.getRecentMedications();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getRecentMedications() {
      this.subscription.add(this.patientService.getPatientRecentMedications(this.patient.id).subscribe((result) => {
        this.recentMedicationsList = result.length > 0 ? result : ['None'];
      }));
    }
  
  updateSelectedRecentMedications() {
    const selected = this.recentMedicationsList.filter((medication) => this.recentMedicationControl.value.includes(medication));
    this.selectedRecentMedications = selected;
  }

  copyToClipboard() {
    const clipBoardString = this.selectedRecentMedications.join('\n');
    navigator.clipboard.writeText(clipBoardString);
    this.snackBar.open('Copied To Clipboard', 'Dismiss', { duration: 2000 });

    // Clear the selections after copying
    this.recentMedicationControl.setValue([]);
    this.selectedRecentMedications = [];
    this.subscription.add(this.recentMedicationControl.valueChanges.subscribe(this.updateSelectedRecentMedications.bind(this)));

    // Close the dropdown
    const dropdown = document.querySelector('.mat-select-panel') as HTMLElement;
    if (dropdown) {
      dropdown.style.display = 'none';
    }
  }
}
