import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../configuration-management/services/account.service';
import { User } from '../../configuration-management/models/user.interface';
import { SyncService } from '../../shared/offline/sync-service';
import { LayoutService } from '../services/layout.service';
import {ConnectionService} from '../../helpers/offline-detection';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  events: string[] = [];
  opened = true;
  shouldRun = true;
  search: boolean;

  connectionNotice: MatSnackBarRef<SimpleSnackBar> = null;

  constructor(
    public accountService: AccountService,
    public connectionService: ConnectionService,
    public snackBar: MatSnackBar,
    public syncService: SyncService,
    private router: Router,
    public layoutService: LayoutService

  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);
   }

  get route() {

    if (this.layoutService.route) {

      return this.layoutService.route;
    }

    return { routeName: '', routeText: ''};
  }

  get altRoute() {
    if (this.layoutService.altRoute) {
      return this.layoutService.altRoute;
    }

    return { routeName: '', routeText: ''};
  }


  routeToQuickEntry() {
    if (this.layoutService.quickEntry !== '' && this.layoutService.quickEntry !== undefined) {
      this.router.navigateByUrl(this.layoutService.quickEntry);
    } else {
      this.router.navigateByUrl('quick-entry');
    }
  }

  ngOnInit() {
    this.connectionService.monitor().subscribe(res => {
      // todo remove after testing in deployed env
      // console.log('connection monitor subscription', res.hasInternetAccess, this.connectionNotice);
      if (res.hasInternetAccess && this.connectionNotice) {
        this.clearConnectionNotice();
      } else if (!res.hasInternetAccess && !this.connectionNotice) {
        console.log('open connection notice');
        this.connectionNotice = this.snackBar.open('You lost your internet connection. ' +
          'To avoid data loss, don\'t refresh the page. Upon being reconnected, you\'ll be able to resume working.', 'OK (Esc)', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.connectionNotice && event.key === 'Escape') {
      this.clearConnectionNotice();
    }
  }

  private clearConnectionNotice() {
    console.log('clear notice');
    try {
      this.connectionNotice.dismiss();
    } catch {} finally {
      this.connectionNotice = null;
    }
  }

  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

}
